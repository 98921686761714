/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import {
  Container,
  Grid,
  Header,
  List,
  Menu,
  Segment,
  Icon,
} from "semantic-ui-react"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet async={false}>
        {/*<!-- You MUST include jQuery before Fomantic -->*/}
        <script src="https://cdn.jsdelivr.net/npm/jquery@3.3.1/dist/jquery.min.js"></script>
        <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/fomantic-ui@2.8.7/dist/semantic.min.css"/>
        <script src="https://cdn.jsdelivr.net/npm/fomantic-ui@2.8.7/dist/semantic.min.js"></script>

        <style id='iframe-style' type="text/css">{`
          div#misconception-body > p > code.language-text {
            background-color: #ffffff40;
            color: white;
          }
        `}</style>
      </Helmet>

      <Menu fixed="top" inverted>
        <Container>
          <Menu.Item as={Link} to="/" header>
            {/*<Image size='mini' src='../images/progmiscon-logo-64.png' style={{ marginRight: '1.5em' }} />*/}
            {data.site.siteMetadata.title}
          </Menu.Item>
          <Menu.Item as={Link} to="/">
            Home
          </Menu.Item>
          <Menu.Item as={Link} to="/misconceptions">
            Misconceptions
          </Menu.Item>
          <Menu.Item as={Link} to="/concepts">
            Concepts
          </Menu.Item>
          <Menu.Item as={Link} to="/specs">
            Languages
          </Menu.Item>
          <Menu.Item as={Link} to="/textbooks">
            Textbooks
          </Menu.Item>
          <Menu.Item as={Link} to="/contributors">
            Contributors
          </Menu.Item>
          <Menu.Item as={Link} to="/about">
            About
          </Menu.Item>
        </Container>
      </Menu>

      <main style={{ marginTop: "40px" }}>{children}</main>

      <Segment inverted vertical style={{ padding: "5em 0em" }}>
        <Container>
          <Grid divided inverted stackable>
            <Grid.Row>
              <Grid.Column width={3}>
                <Header inverted as="h4" content="Find Misconceptions by..." />
                <List link inverted>
                  <List.Item as={Link} to="/concepts">
                    <List.Icon name='lightbulb' />
                    <List.Content>Concept</List.Content>
                  </List.Item>
                  <List.Item as={Link} to="/specs">
                    <List.Icon name='code' />
                    <List.Content>Programming Language</List.Content>
                  </List.Item>
                  <List.Item as={Link} to="/notionalMachines">
                    <List.Icon name='settings'></List.Icon>
                    <List.Content>Notional Machine</List.Content>
                  </List.Item>
                  <List.Item as={Link} to="/textbooks">
                    <List.Icon name='book'></List.Icon>
                    <List.Content>Textbook</List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header inverted as="h4" content="About" />
                <List link inverted>
                  <List.Item as={Link} to="/about">
                    About This Site
                  </List.Item>
                  <List.Item as={Link} to="/contributors">
                    Contributors
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={7}>
                <Header as="h4" inverted>
                  Stay up-to-date
                </Header>
                <p>
                  Follow us on &nbsp;
                  <a href="https://twitter.com/progMiscon">
                    <Icon name="twitter" />
                    twitter
                  </a>
                  &nbsp;to hear about new misconceptions.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
